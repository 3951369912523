:root {
  --siderbar-width: 200px;
}
/* purgecss ignore */
@tailwind base;
@tailwind components;
@tailwind utilities;
.anticon {
  vertical-align: baseline !important;
}
